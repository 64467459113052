import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { MuiThemeProvider, createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

import Header from './components/Header';
import Footer from './components/Footer';
import HomePage from './pages/HomePage';
import ContactPage from './pages/ContactPage';

const theme = responsiveFontSizes(
	createMuiTheme({
		typography: {
			fontFamily: ['Poppins', 'sans-serif'].join(','),
		},
	})
);

function App() {
	return (
		<MuiThemeProvider theme={theme}>
			<div className="App" style={{ padding: '10px' }}>
				<Router>
					<Header />
					<Switch>
						<Route path="/contact">
							<ContactPage />
						</Route>
						<Route path="/">
							<HomePage />
						</Route>
					</Switch>
					<Footer />
				</Router>
			</div>
		</MuiThemeProvider>
	);
}

export default App;
