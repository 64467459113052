import React from 'react';
import Link from '@material-ui/core/Link';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import CssBaseline from '@material-ui/core/CssBaseline';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import Slide from '@material-ui/core/Slide';
import Fab from '@material-ui/core/Fab';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import Zoom from '@material-ui/core/Zoom';

import MailIcon from '@material-ui/icons/Mail';

const useStyles = makeStyles((theme) => ({
	appBarRoot: {
		padding: '10px',
		width: '100%',
		backgroundColor: '#212121',
	},
	scroll: {
		position: 'fixed',
		bottom: '20px',
		right: '20px',
		zIndex: 1,
	},
	title: {
		flexGrow: 1,
	},
	link: {
		textDecoration: 'none',
		color: 'white',
	},
	linkIcon: {
		verticalAlign: 'middle',
	},
}));

function HideOnScroll(props) {
	const { children, window } = props;
	const trigger = useScrollTrigger({ target: window ? window() : undefined });

	return (
		<Slide appear={false} direction="down" in={!trigger}>
			{children}
		</Slide>
	);
}

function ScrollTop(props) {
	const { children, window } = props;
	const classes = useStyles();

	const trigger = useScrollTrigger({
		target: window ? window() : undefined,
		disableHysteresis: true,
		threshold: 100,
	});

	const handleClick = (event) => {
		const anchor = (event.target.ownerDocument || document).querySelector('#back-to-top-anchor');
		if (anchor) anchor.scrollIntoView({ behavior: 'smooth', block: 'center' });
	};

	return (
		<Zoom in={trigger}>
			<div onClick={handleClick} role="presentation" className={classes.scroll}>
				{children}
			</div>
		</Zoom>
	);
}

HideOnScroll.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};

ScrollTop.propTypes = {
	children: PropTypes.element.isRequired,
	window: PropTypes.func,
};

export default function Header(props) {
	const classes = useStyles();

	return (
		<React.Fragment>
			<CssBaseline />
			<HideOnScroll>
				<AppBar className={classes.appBarRoot}>
					<Toolbar>
						<Typography variant="h6" className={classes.title}>
							Fuad Chonora
						</Typography>
						<Link href="mailto:fuadchonora@gmail.com" color="inherit" className={classes.link}>
							<Typography variant="h6" className={classes.title}>
								<MailIcon className={classes.linkIcon} /> Say Hello
							</Typography>
						</Link>
					</Toolbar>
				</AppBar>
			</HideOnScroll>
			<Toolbar id="back-to-top-anchor" />
			<ScrollTop className={classes.scroll}>
				<Fab color="secondary" size="small" aria-label="scroll back to top">
					<KeyboardArrowUpIcon />
				</Fab>
			</ScrollTop>
		</React.Fragment>
	);
}
