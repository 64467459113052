import React from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import LinkedInIcon from '@material-ui/icons/LinkedIn';
import InstagramIcon from '@material-ui/icons/Instagram';
import TwitterIcon from '@material-ui/icons/Twitter';
import YouTubeIcon from '@material-ui/icons/YouTube';

import SocialBg from '../assets/img/social.jpg';

const useStyles = makeStyles((theme) => ({
	rootGrid: {
		textAlign: 'center',
	},
	itemGrid: {
		paddingTop: '16px !important',
		minHeight: '50vh',
	},
	footerGrid: {
		minHeight: '10vh',
	},
	section_social: {
		height: '50vh',
		backgroundImage: `url(${SocialBg})`,
		backgroundPosition: 'center center',
		backgroundSize: 'cover',
		color: '#212121',
		borderRadius: '34px',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'relative',
	},
	section_cc: {
		height: '100px',
		backgroundColor: '#111111',
		color: 'white',
		borderRadius: '34px',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'relative',
	},
	center_text: {
		width: '100%',
		margin: 0,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
	socialButton: {
		marginRight: '0px',
		marginLeft: '0px',
		fontSize: '48px !important',
	},
}));

export default function Footer() {
	const classes = useStyles();

	return (
		<Grid container spacing={2} className={classes.rootGrid}>
			<Grid item xs={12} className={classes.itemGrid}>
				<div className={classes.section_social}>
					<div className={classes.center_text}>
						<Box m={1}>
							<Typography style={{ fontWeight: 800 }} variant="h2" component="h2" gutterBottom>
								Social
							</Typography>
						</Box>

						<Box m={1}>
							<Typography variant="body1" component="p" gutterBottom>
								<Button
									color="inherit"
									startIcon={<LinkedInIcon className={classes.socialButton} />}
									href="https://linkedin.com/in/fuadchonora"
								/>
								<Button
									color="inherit"
									startIcon={<InstagramIcon className={classes.socialButton} />}
									href="https://instagram.com/fuadchonora"
								/>
								<Button
									color="inherit"
									startIcon={<TwitterIcon className={classes.socialButton} />}
									href="https://twitter.com/fuadchonora"
								/>
								<Button
									color="inherit"
									startIcon={<YouTubeIcon className={classes.socialButton} />}
									href="https://youtube.com/fuadchonora"
								/>
							</Typography>
						</Box>
					</div>
				</div>
			</Grid>

			<Grid item xs={12} className={classes.footerGrid}>
				<div className={classes.section_cc}>
					<div className={classes.center_text}>
						<Box fontSize={16} m={1}>
							<Typography style={{ fontWeight: 600 }} variant="body1" component="p" gutterBottom>
								©{' '}
								<Link style={{ textDecoration: 'none', color: 'inherit' }} to="/">
									{' '}
									Fuad Chonora &#183;{' '}
								</Link>{' '}
								{new Date().getFullYear()}
							</Typography>
						</Box>
					</div>
				</div>
			</Grid>
		</Grid>
	);
}
