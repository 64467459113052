import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';

import TravelBg from '../assets/img/travel.jpg';

const useStyles = makeStyles((theme) => ({
	rootGrid: {
		textAlign: 'center',
	},
	itemGrid: {
		minHeight: '50vh',
	},
	section1: {
		minHeight: '84vh',
		marginTop: '20px',
		backgroundImage: `url(${TravelBg})`,
		backgroundRepeat: 'no-repeat',
		backgroundPosition: 'center center',
		backgroundSize: '100% auto',
		[theme.breakpoints.down('sm')]: {
			backgroundPositionX: 'center',
			backgroundPositionY: 'center',
			backgroundSize: 'auto 100%',
		},
		[theme.breakpoints.down('xs')]: {
			minHeight: '87vh',
			backgroundPositionX: '-500px',
			backgroundPositionY: 'center',
			backgroundSize: 'auto 100%',
		},
		display: 'flex',
		flexDirection: 'column',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#212121',
		borderRadius: '34px',
	},
	welcomeText: {
		marginTop: '30%',
		[theme.breakpoints.down('sm')]: {
			marginTop: '40%',
		},
	},
	section2: {
		height: '50vh',
		display: 'flex',
		backgroundColor: '#82b1ff',
		borderRadius: '34px',
		alignItems: 'center',
		justifyContent: 'center',
		color: '#212121',
	},
	section3: {
		height: '50vh',
		backgroundColor: '#1de9b6',
		color: '#212121',
		borderRadius: '34px',
		alignItems: 'center',
		justifyContent: 'center',
		textAlign: 'center',
		position: 'relative',
	},
	center_text: {
		width: '100%',
		margin: 0,
		position: 'absolute',
		top: '50%',
		left: '50%',
		transform: 'translate(-50%, -50%)',
	},
}));

export default function HomePage() {
	const classes = useStyles();

	return (
		<Grid container spacing={2} className={classes.rootGrid}>
			<Grid item xs={12} className={classes.itemGrid}>
				<div className={classes.section1}></div>
			</Grid>

			<Grid item xs={12} md={12} className={classes.itemGrid}>
				<div className={classes.section2}>
					<Box m={1}>
						<Typography style={{ fontWeight: 800 }} variant="h2" component="h2" gutterBottom>
							Solution-Oriented, Experienced, and Professional
						</Typography>
					</Box>
				</div>
			</Grid>

			<Grid item xs={12} md={4} className={classes.itemGrid}>
				<div className={classes.section3}>
					<div className={classes.center_text}>
						<Box m={1}>
							<Typography style={{ fontWeight: 800 }} variant="h3" component="h2" gutterBottom>
								My Experience
							</Typography>
						</Box>
						<Box fontSize={16} m={1}>
							<Typography style={{ fontWeight: 800 }} variant="body1" component="p" gutterBottom>
								I graduated from college having majored in computer science with a minor in dev-ops. I have
								been working as a JavaScript Engineer for the past two years as well as freelancing as a web
								developer.
							</Typography>
						</Box>
					</div>
				</div>
			</Grid>

			<Grid item xs={12} md={4} className={classes.itemGrid}>
				<div className={classes.section3}>
					<div className={classes.center_text}>
						<Box m={1}>
							<Typography style={{ fontWeight: 800 }} variant="h3" component="h2" gutterBottom>
								My Skillset
							</Typography>
						</Box>
						<Box fontSize={16} m={1}>
							<Typography style={{ fontWeight: 800 }} variant="body1" component="p" gutterBottom>
								I possess a sharp eye for detail, which I use to find even the smallest errors. I work well
								under pressure and produce high-quality work in short periods. I have strong interpersonal
								skills and I work with a wide variety of people.
							</Typography>
						</Box>
					</div>
				</div>
			</Grid>

			<Grid item xs={12} md={4} className={classes.itemGrid}>
				<div className={classes.section3}>
					<div className={classes.center_text}>
						<Box m={1}>
							<Typography style={{ fontWeight: 800 }} variant="h3" component="h2" gutterBottom>
								My Goals
							</Typography>
						</Box>
						<Box fontSize={16} m={1}>
							<Typography style={{ fontWeight: 800 }} variant="body1" component="p" gutterBottom>
								I am looking for an opportunity to work with a team that runs on clear communication. I want
								to align myself with a company I believe in and where I can create a positive change. I am
								always looking to learn more and am open to taking on challenging projects.
							</Typography>
						</Box>
					</div>
				</div>
			</Grid>
		</Grid>
	);
}
